import styled from '@emotion/styled';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import Chip from '@zola/zola-ui/src/components/Chip';

import { StyledWrapper } from '@zola/zola-ui/src/components/Chip/Chip.styles';

import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const Header = styled.h1`
  ${FONT.textSizeRegular(FONT.SIZE.LARGER)}
  font-family: ${FONT.FAMILY.CIRCULAR};
  color: ${COLORS3.BLACK_100};
  font-style: ${FONT.WEIGHT.REGULAR};
  letter-spacing: -1.28px;
  margin-bottom: ${SPACING.XS};
`;

export const Desc = styled.p`
  ${FONT.textSizeRegular(FONT.SIZE.SMALL)}
  color: ${COLORS3.BLACK_075};
  letter-spacing: 0.32px;
  margin: 0 0 ${SPACING.S32} 0;
`;

export const FormInnerContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: ${SPACING.S24};

  ${MEDIA_QUERY.MOBILE} {
    flex-wrap: wrap;
  }

  .zola-ui.v2-render-field label {
    ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
    color: ${COLORS3.BLACK_100};
    letter-spacing: 0.32px;
    margin: 0 0 ${SPACING.XS} 0;
    text-transform: none;
    display: inline-block;

    span {
      margin: 0;
    }
  }

  .zola-ui.v2-render-field .input-field__container {
    margin: 0;
  }

  & > .zola-ui.v2-render-field {
    &:first-of-type {
      input {
        max-width: 76px;
        width: 100%;
        margin-right: ${SPACING.S16};
      }
    }

    &:last-of-type {
      max-width: 367px;
      width: 100%;
      margin-right: ${SPACING.S16};

      ${MEDIA_QUERY.MOBILE} {
        max-width: 100%;
        width: auto;
        margin-right: 0;
        flex: 1;
      }
    }
  }
`;

export const StyledSearchBtn = styled(ButtonV3)`
  margin-top: ${SPACING.S24};

  ${MEDIA_QUERY.MOBILE} {
    display: block;
    max-width: 100%;
    width: 100%;
    margin-top: ${SPACING.S32};
  }
`;

export const StyledShowFormBtn = styled(ButtonV3)`
  display: block;
  max-width: 100%;
  width: 100%;
`;

export const NameChip = styled(Chip)`
  display: block;
  max-width: 100%;
  width: 100%;
  margin-bottom: ${SPACING.XS};

  ${StyledWrapper} {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
  }
`;

export const ShowFormBtnContainer = styled.div<{ mobileShowSearchForm?: boolean }>`
  display: none;

  ${MEDIA_QUERY.TABLET} {
    display: ${({ mobileShowSearchForm }) => (!mobileShowSearchForm ? 'block' : 'none')};
  }

  ${MEDIA_QUERY.MOBILE} {
    display: ${({ mobileShowSearchForm }) => (!mobileShowSearchForm ? 'block' : 'none')};
  }
`;

export const FormContainer = styled.div<{ mobileShowSearchForm?: boolean }>`
  display: none;

  ${MEDIA_QUERY.TABLET} {
    display: ${({ mobileShowSearchForm }) => (mobileShowSearchForm ? 'block' : 'none')};
  }

  ${MEDIA_QUERY.MOBILE} {
    display: ${({ mobileShowSearchForm }) => (mobileShowSearchForm ? 'block' : 'none')};
  }

  ${MEDIA_QUERY.DESKTOP} {
    display: block;
  }
`;
