import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WPublicGuestCollectionDetailView } from '@zola/svc-web-api-ts-client';

import { AddressFormValues } from 'components/ContactCollectorGuestView/@types/form';

export const updateAddressDetails = (
  guestGroupUuid: string,
  options: AddressFormValues
): Promise<WPublicGuestCollectionDetailView> => {
  return ApiService.put(
    `${window.location.origin}/web-api/v1/guestgroup/uuid/${guestGroupUuid}/update_address`,
    { ...options }
  );
};

export const createAddressDetails = (
  options: AddressFormValues
): Promise<WPublicGuestCollectionDetailView> => {
  return ApiService.post(`${window.location.origin}/web-api/v1/guestgroup/collect_address/new`, {
    ...options,
  });
};
