import styled from '@emotion/styled';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const GuestNames = styled.p`
  ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
  margin-bottom: ${SPACING.XS};
`;

export const GuestInfoSbsContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  flex-wrap: nowrap;

  p {
    margin: 0;
    padding: 0;
    ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
  }
`;

export const IconContainer = styled.div`
  margin-right: ${SPACING.SM};
  margin-top: 6px;
`;

export const DislaimerTxt = styled.p`
  ${FONT.textSizeRegular(FONT.SIZE.SMALL)}
  color: ${COLORS3.BLACK_050};
  margin: ${SPACING.S24} 0;
`;

export const StyledBtn = styled(ButtonV3)`
  width: 100%;
  max-width: 100%;
  margin-bottom: ${SPACING.S16};
`;

export const StyledLnk = styled(LinkV2)`
  display: block;
  width: 100%;
  max-width: 100%;
  text-align: center;
  text-decoration: underline !important;
  text-transform: none;
`;
