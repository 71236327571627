const NamePrefix = [
  {
    value: 'MR',
    label: 'Mr.',
  },
  {
    value: 'MRS',
    label: 'Mrs.',
  },
  {
    value: 'MS',
    label: 'Ms.',
  },
  {
    value: 'MISS',
    label: 'Miss',
  },
  {
    value: 'MX',
    label: 'Mx.',
  },
  {
    value: 'DR',
    label: 'Dr.',
  },
  {
    value: 'JUDGE',
    label: 'Judge',
  },
];

export default NamePrefix;
