import React from 'react';
import Link from 'next/link';
import type { WPublicGuestCollectionDetailView } from '@zola/svc-web-api-ts-client';

// utils
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

// styles
import { StepContainer } from 'components/ContactCollectorGuestView/ContactCollectorGuestView.styles';
import { Header, Desc, CompleteTxt, ZolaTxt, StyledGetToKnowBtn } from './CompletionPage.styles';

interface CompletionPageProps {
  guestName: string | undefined;
  weddingDetails: WPublicGuestCollectionDetailView;
}

const CompletionPage: React.FC<CompletionPageProps> = ({ weddingDetails, guestName }) => {
  const coupleNames = `${capitalizeFirst(
    weddingDetails.owner_first_name as string
  )} & ${capitalizeFirst(weddingDetails.partner_first_name as string)}`;
  const textConfig = {
    title: "Also 👋 we're Zola!",
    text: (
      <span>
        We help engaged couples bring their weddings to life.
        <br />
        If you&apos;re getting married, let&apos;s chat.
      </span>
    ),
    ctaText: 'Get to know us',
    href: '/',
  };

  return (
    <StepContainer>
      <div>
        <Header>
          You&apos;re All Set, <span>{capitalizeFirst(guestName as string)}!</span>
        </Header>
        <Desc>{coupleNames} are doing a happy dance.</Desc>
        <div>
          <ZolaTxt>{textConfig.title}</ZolaTxt>
          <CompleteTxt>{textConfig.text}</CompleteTxt>
          <Link href={textConfig.href}>
            <StyledGetToKnowBtn
              component="a"
              role="link"
              size="medium"
              mobileSize="large"
              textTransform={false}
            >
              {textConfig.ctaText}
            </StyledGetToKnowBtn>
          </Link>
        </div>
      </div>
    </StepContainer>
  );
};

export default CompletionPage;
