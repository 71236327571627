import styled from '@emotion/styled';

import { CollectionSteps } from 'pages/addr/[id]';

import PanelDesktop from 'public/static-preauth/images/ContactCollector/contact-collector-panel-desktop.jpg';
import PanelTablet from 'public/static-preauth/images/ContactCollector/contact-collector-panel-tablet.jpg';
import PanelMobile from 'public/static-preauth/images/ContactCollector/contact-collector-panel-mobile.jpg';
import PanelDesktopForPhoto from 'public/static-preauth/images/ContactCollector/contact-collector-panel-desktop-no-we-do.jpg';

import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';

const formatBgImgStyle = (asset: string) => `background-image: url(${asset})`;

const getBackgroundImage = (
  deviceSize: 'desktop' | 'tablet' | 'mobile',
  hasCouplePhoto?: boolean
) => {
  if (hasCouplePhoto) {
    if (deviceSize === 'mobile' || deviceSize === 'tablet') {
      return 'background-image: none';
    }
    return formatBgImgStyle(PanelDesktopForPhoto);
  }
  if (deviceSize === 'mobile') {
    return formatBgImgStyle(PanelMobile);
  }
  if (deviceSize === 'tablet') {
    return formatBgImgStyle(PanelTablet);
  }
  return formatBgImgStyle(PanelDesktop);
};

export const Container = styled.div`
  background-color: ${COLORS3.WHITE_100};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: ${ZINDEX.bottom};

  ${MEDIA_QUERY.DESKTOP} {
    flex-direction: row;
    height: 100vh;
  }
`;

export const ContentContainer = styled.div<{ step?: number; mobileShowSearchForm?: boolean }>`
  display: none;

  border-bottom: 1px solid ${COLORS3.BLACK_030};
  flex: 1 1 100%;
  padding: ${SPACING.S16};
  position: relative;

  ${MEDIA_QUERY.DESKTOP} {
    border-bottom: none;
    border-right: 1px solid ${COLORS3.BLACK_030};
    flex: 1 1 40%;
    max-width: 768px;
    display: block;
  }

  ${MEDIA_QUERY.TABLET} {
    height: 276px;
  }
  ${MEDIA_QUERY.MOBILE} {
    height: 260px;
  }

  ${MEDIA_QUERY.TABLET} {
    display: ${({ step, mobileShowSearchForm }) =>
      step === CollectionSteps.SearchStep && !mobileShowSearchForm ? 'block' : 'none'};
  }

  ${MEDIA_QUERY.MOBILE} {
    display: ${({ step, mobileShowSearchForm }) =>
      step === CollectionSteps.SearchStep && !mobileShowSearchForm ? 'block' : 'none'};
  }
`;

export const Content = styled.div<{ hasCouplePhoto?: boolean }>`
  border-radius: 16px;
  height: 100%;
  overflow: hidden;

  background-color: #26425f;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ hasCouplePhoto }) => getBackgroundImage('desktop', hasCouplePhoto)};

  ${MEDIA_QUERY.TABLET} {
    ${({ hasCouplePhoto }) => getBackgroundImage('tablet', hasCouplePhoto)};
    min-height: 244px;
  }

  ${MEDIA_QUERY.MOBILE} {
    ${({ hasCouplePhoto }) => getBackgroundImage('mobile', hasCouplePhoto)};
    min-height: 228px;
  }
`;

export const SectionsContainer = styled.div`
  flex-basis: 100%;
  overflow: auto;
  padding-top: 52px;
  padding: ${SPACING.MD};

  ${MEDIA_QUERY.DESKTOP} {
    flex: 1 1 60%;
    position: relative;
  }
`;

export const SectionsContent = styled.div<{ isHidden?: boolean }>`
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  ${({ isHidden }) => isHidden && `display: none;`}

  ${MEDIA_QUERY.TABLET} {
    padding-bottom: ${SPACING.XL};
  }

  ${MEDIA_QUERY.MOBILE} {
    padding-bottom: ${SPACING.XL};
  }

  .field-message-container {
    /* Prevents filed validation from shifting */
    position: relative;

    & > span {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      transform: translateY(100%);
    }
  }
`;

export const CouplePhoto = styled.img`
  border-radius: ${SPACING.S16};
  width: 326px;
  height: 326px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    width: 180px;
    height: 180px;
  }
`;

/**
 * COMMON STYLED COMPONENTS
 */
export const StepContainer = styled.div`
  padding-top: 60px;
  width: 100%;
  max-width: 100%;

  ${MEDIA_QUERY.TABLET} {
    padding-top: ${SPACING.MD};
  }

  ${MEDIA_QUERY.MOBILE} {
    padding-top: ${SPACING.XXS};
  }
`;

export const FormHeader = styled.h2`
  ${FONT.textSizeCompact(FONT.SIZE.LARGE)}
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  color: ${COLORS3.BLACK_100};
  margin: 0 0 ${SPACING.S16} 0;
  letter-spacing: -0.72px;
`;

export const Spacer = styled.div`
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    height: 2px;
  }
`;
