import styled from '@emotion/styled';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';

export const StyledReviewBtn = styled(ButtonV3)`
  width: 100%;
  max-width: 100%;
  margin-top: ${SPACING.S32};

  ${MEDIA_QUERY.MOBILE} {
    margin-top: 0;
  }
`;

export const StyledReviewBtnContainer = styled.div`
  ${MEDIA_QUERY.MOBILE} {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: ${SPACING.S16} ${SPACING.S24};
    border-top: 1px solid ${COLORS3.BLACK_030};
    background-color: ${COLORS3.WHITE_100};
  }
`;

const labelStyles = `
  .zola-ui.v2-render-field .input-field__container {
    margin: 0;

    ${MEDIA_QUERY.MOBILE} {
      margin-bottom: ${SPACING.S16}
    }
  }

  .zola-ui.v2-render-field label {
    ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
    color: ${COLORS3.BLACK_100};
    letter-spacing: 0.32px;
    margin: 0 0 ${SPACING.XS} 0;
    text-transform: none;
    display: inline-block;

    span {
      margin: 0;
    }
  }
`;

export const UnknownGuestContainer = styled.div`
  margin-bottom: ${SPACING.S16};

  & > .zola-ui.v2-render-field {
    max-width: 152px;
    width: 100%;
  }

  ${labelStyles}
`;

export const UnknownCheckboxContainer = styled.div`
  margin-bottom: ${SPACING.S16};
`;

export const GuestContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: ${SPACING.S16};

  ${MEDIA_QUERY.MOBILE} {
    flex-wrap: wrap;
  }

  & > .zola-ui.v2-render-field {
    max-width: calc(40% - ${SPACING.S16});
    width: 100%;
    margin-right: ${SPACING.S16};

    ${MEDIA_QUERY.MOBILE} {
      max-width: calc(65%);
    }

    &:first-of-type,
    &:last-of-type {
      max-width: calc(20% - ${SPACING.S16});

      ${MEDIA_QUERY.MOBILE} {
        max-width: calc(35% - ${SPACING.S16});
      }
    }

    ${MEDIA_QUERY.MOBILE} {
      &:nth-of-type(2) {
        margin-right: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${labelStyles}

  .dropdown-v2__container.zola-ui.v2-render-field {
    margin-top: -3px;

    ${MEDIA_QUERY.MOBILE} {
      margin-top: 0;
    }
  }

  .dropdown-v2__container {
    min-width: unset;
  }

  @media screen and (max-width: 480px) {
    .dropdown-v2__options {
      z-index: ${ZINDEX.top};
    }
    .dropdown-v2__options {
      z-index: ${ZINDEX.top};
    }
  }
`;

export const OuterLabel = styled.span`
  position: relative;
`;

export const InnerLabel = styled.span`
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)}
  color: ${COLORS3.BLACK_075};
  text-transform: none;

  ${MEDIA_QUERY.MOBILE} {
    position: absolute;
    transform: translate(4px, 1px);
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: ${SPACING.S16};

  ${MEDIA_QUERY.MOBILE} {
    flex-wrap: wrap;
  }

  & > .zola-ui.v2-render-field {
    max-width: 50%;
    width: 100%;
    margin-right: ${SPACING.S16};

    &:first-of-type {
      ${MEDIA_QUERY.MOBILE} {
        max-width: calc(75% - ${SPACING.S16});
      }
    }

    &:last-of-type {
      margin-right: 0;
      ${MEDIA_QUERY.MOBILE} {
        max-width: 25%;
      }
    }
  }

  ${labelStyles}
`;

export const EmailMobileContainer = styled(AddressContainer)`
  & > .zola-ui.v2-render-field {
    &:first-of-type,
    &:last-of-type {
      ${MEDIA_QUERY.MOBILE} {
        margin-right: 0;
        max-width: 100%;
      }
    }
  }
`;

export const CountryContainer = styled.div`
  margin-bottom: ${SPACING.S16};

  ${labelStyles}

  @media screen and (max-width: 480px) {
    .dropdown-v2__options {
      z-index: ${ZINDEX.middle};
    }
    .dropdown-v2__options-mobile-header {
      z-index: ${ZINDEX.middle};
    }
  }
`;

export const CityStateZipContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: ${SPACING.S16};

  ${MEDIA_QUERY.MOBILE} {
    flex-wrap: wrap;
  }

  & > .zola-ui.v2-render-field {
    max-width: 40%;
    width: 100%;
    margin-right: ${SPACING.S16};

    &:last-of-type {
      max-width: 20%;
      margin-right: 0;
    }

    ${MEDIA_QUERY.MOBILE} {
      max-width: calc(50% - ${SPACING.S16});

      &:first-of-type {
        max-width: 100%;
        margin-right: 0;
      }

      &:last-of-type {
        max-width: 50%;
      }
    }
  }

  ${labelStyles}

  .dropdown-v2__container.zola-ui.v2-render-field {
    margin-top: -3px;

    ${MEDIA_QUERY.MOBILE} {
      margin-top: 0;
    }
  }

  .dropdown-v2__container {
    min-width: unset;
  }

  @media screen and (max-width: 480px) {
    .dropdown-v2__options {
      z-index: ${ZINDEX.top};
    }
    .dropdown-v2__options {
      z-index: ${ZINDEX.top};
    }
  }
`;
