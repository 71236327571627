import styled from '@emotion/styled';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const Header = styled.h1`
  color: ${COLORS3.BLACK_100};
  font-family: ${FONT.FAMILY.CIRCULAR};
  font-size: ${FONT.SIZE.SEMI_HUGE};
  font-style: normal;
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  line-height: ${FONT.SIZE.SEMI_HUGE};
  letter-spacing: -1.92px;
  margin: 0 0 ${SPACING.S16} 0;

  ${MEDIA_QUERY.MOBILE} {
    font-size: ${FONT.SIZE.LARGER};
    line-height: ${SPACING.LG};
  }
`;

export const Desc = styled.p`
  ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)}
  margin: 0 0 56px 0;

  ${MEDIA_QUERY.MOBILE} {
    margin: 0 0 ${SPACING.S32} 0;
  }
`;

export const CompleteTxt = styled.p`
  ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
  margin: 0 0 ${SPACING.S16} 0;

  ${MEDIA_QUERY.MOBILE} {
    margin: 0 0 ${SPACING.S32} 0;
  }
`;

export const ZolaTxt = styled.p`
  ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  margin: 0 0 ${SPACING.XXS} 0;
`;

export const StyledGetToKnowBtn = styled(ButtonV3)`
  ${MEDIA_QUERY.MOBILE} {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`;
