import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';

// components
import { InputFieldV2Adapter } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import { ArrowNarrowRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ArrowNarrowRight';

// utils
import { composeValidators, Validations } from '@zola/zola-ui/src/components/Form/util';
import { EXACT_LENGTH } from 'components/forms/validator';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';

// types
import type {
  WPublicGuestCollectionDetailView,
  WCollectAddressGuestGroupGuestMatchView,
} from '@zola/svc-web-api-ts-client';

// styles
import {
  StepContainer,
  FormHeader,
} from 'components/ContactCollectorGuestView/ContactCollectorGuestView.styles';
import {
  Desc,
  FormInnerContainer,
  Header,
  StyledSearchBtn,
  NameChip,
  FormContainer,
  StyledShowFormBtn,
  ShowFormBtnContainer,
} from './GuestSearchForm.styles';

const { maxLength: MAX_LENGTH, REQUIRED } = Validations;

interface CoupleSearchFormProps {
  onSubmit: (result: WCollectAddressGuestGroupGuestMatchView | null) => void;
  weddingDetails: WPublicGuestCollectionDetailView;
  mobileShowSearchForm: boolean;
  setMobileShowSearchForm: (show: boolean) => void;
}

interface Values {
  initial: string;
  lastName: string;
}

const GuestSearchForm: React.FC<CoupleSearchFormProps> = ({
  onSubmit,
  weddingDetails,
  mobileShowSearchForm,
  setMobileShowSearchForm,
}) => {
  const [guests, setGuests] = useState<WCollectAddressGuestGroupGuestMatchView[] | undefined>();

  const { wedding_account_uuid: weddingAccountId } = weddingDetails;

  const submitGuestSearchForm = async (values: Values) => {
    const URL = `${window.location.origin}/web-api/v1/guestgroup/collect_address/search`;
    const result = await ApiService.post<WCollectAddressGuestGroupGuestMatchView[]>(URL, {
      family_name: values.lastName,
      first_initial: values.initial,
      limit: 0,
      offset: 0,
      wedding_account_uuid: weddingAccountId,
    });
    setGuests(result);
    if (result.length === 0) {
      onSubmit(null);
    }
  };

  const renderGuestSelection = () => {
    if (guests && guests.length > 0) {
      const guestSelection = guests.map(guest => (
        <NameChip
          key={`${guest.first_name}-${guest.family_name}`}
          onClick={() => {
            onSubmit(guest);
          }}
        >
          <span>
            {`${capitalizeFirst(guest.first_name as string)} ${capitalizeFirst(
              guest.family_name as string
            )}`}{' '}
          </span>
          <ArrowNarrowRightIcon height={24} width={24} />
        </NameChip>
      ));

      return (
        <section>
          <FormHeader>Select your name to unlock your private form</FormHeader>
          {guestSelection}
          <NameChip
            key="no-match"
            onClick={() => {
              onSubmit(null);
            }}
          >
            <span>I don&apos;t see my name</span>
            <ArrowNarrowRightIcon height={24} width={24} />
          </NameChip>
        </section>
      );
    }
    return null;
  };

  return (
    <StepContainer data-testid="GuestSearchForm">
      {weddingDetails?.owner_first_name && weddingDetails?.partner_first_name && (
        <Header>
          {capitalizeFirst(weddingDetails.owner_first_name)} and{' '}
          {capitalizeFirst(weddingDetails.partner_first_name)} request your address and info.
        </Header>
      )}
      <Desc>
        Something special&apos;s headed your way{' '}
        <span role="img" aria-label="Heart envelope">
          💌
        </span>{' '}
        Don&apos;t worry—your details won&apos;t be shared with anyone else.
      </Desc>
      <ShowFormBtnContainer mobileShowSearchForm={mobileShowSearchForm}>
        <StyledShowFormBtn
          variant="primary"
          size="large"
          onClick={() => setMobileShowSearchForm(true)}
        >
          Fill out form
        </StyledShowFormBtn>
      </ShowFormBtnContainer>
      <FormContainer mobileShowSearchForm={mobileShowSearchForm}>
        <Form<Values>
          onSubmit={submitGuestSearchForm}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <FormHeader>Please enter your first initial and last name</FormHeader>
              <FormInnerContainer>
                <Field<string>
                  component={InputFieldV2Adapter}
                  inline
                  data-testid="input-initial"
                  id="input-initial"
                  name="initial"
                  type="text"
                  label="First initial"
                  validate={composeValidators<string>(REQUIRED, EXACT_LENGTH(1))}
                />
                <Field<string>
                  component={InputFieldV2Adapter}
                  inline
                  id="input-lastname"
                  data-testid="input-lastname"
                  name="lastName"
                  type="text"
                  label="Last name"
                  validate={composeValidators<string>(REQUIRED, MAX_LENGTH(100))}
                />
                <StyledSearchBtn
                  type="submit"
                  disabled={invalid}
                  size="medium"
                  mobileSize="large"
                  textTransform={false}
                >
                  <span>Search</span>
                </StyledSearchBtn>
              </FormInnerContainer>
              <div>{renderGuestSelection()}</div>
            </form>
          )}
        />
      </FormContainer>
    </StepContainer>
  );
};

export default GuestSearchForm;
